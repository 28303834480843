<template>
  <div class="web-container">
    <!--页面顶部-->
    <div class="page-header">
      <div class="header-container">
        <div class="web-title flex flex-start">
          <!--          <img :src="webConfig.logoUrl" alt="" class="logo">-->
          <div class="title-name">陆军军事交通学院镇江校区-{{ webConfig.systemName }}</div>
        </div>
      </div>
    </div>
    <div class="nav-container">
      <span :class="navIndex==='1'?'li active':'li'" @click="clickNavLi('1')">中心介绍</span>
      <span :class="navIndex==='2'?'li active':'li'" @click="clickNavLi('2')">项目介绍</span>
      <!--      <span class="li" @click="clickNavLi('3')">教学团队</span>-->
      <span :class="navIndex==='4'?'li active':'li'" @click="clickNavLi('4')">友情链接</span>
      <span :class="navIndex==='5'?'li active':'li'" style="color: #4093f9" @click="clickNavLi('5')">体验教学系统</span>
    </div>
    <!--轮播图-->
    <div class="focus-container ">
      <el-carousel :interval="6000" height="520px">
        <el-carousel-item v-for="item in webConfig.focusList" :key="item" style="text-align: center">
          <el-image
              style="width: 1920px;height:520px;"
              :src="item"
              fit="contain"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--中心介绍-->
    <a href="#" name="center"></a>
    <div class="li-container content-container">
      <el-divider content-position="center" class="content-title">中心介绍</el-divider>
      <div class="content-html html-view">
        建设中
      </div>
    </div>
    <!--项目介绍-->
    <a href="#" name="experiment"></a>
    <div class="content-container experiment-container">
      <el-divider content-position="center" class="content-title">项目介绍</el-divider>
      <div class="experiment-list">
        <div class="sub-list flex flex-start flex-wrap">
          <div class="sub-li" v-for="(experimentItem,experimentIndex) in experiments.list">
            <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                 v-if="experimentItem.product_iconLeft">
            <div class="bg">
              <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'">
            </div>
            <div class="info">
              <img class="avatar" :src="experimentItem.product_icon" alt="">
              <div class="flex flex-dr flex-center">
                <div class="title">{{ experimentItem.name }}</div>
                <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                <!--最多3行 每行18字 第三行超出省略号显示-->
                <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                <div class="buttons">
                  <a class="button" href="javascript:void(0)"
                     @click="ExperimentMethods().clickViewExperiment(experimentItem)">查看详情</a>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-li more-li">
            <div class="bg">
              <img src="http://resouce.cdzyhd.com/08aba1b4-255f-4540-8089-f947243218d5.png">
            </div>
            <div class="info">

            </div>
          </div>
        </div>
      </div>
    </div>
    <!--产品介绍弹窗-->
    <div v-if="experimentInfo.dialog" class="my-dialog-container flex flex-dr flex-center experiment-dialog">
      <div class="mask" @click="ExperimentMethods().closeExperimentDialog()"></div>
      <div class="dialog-box">
        <div class="bg"></div>
        <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon"
             @click="ExperimentMethods().closeExperimentDialog()">
        <div class="info-box">
          <div class="header-box flex flex-start">
            <img :src="experimentInfo.experiment.product_icon" class="avatar" alt="">
            <div class="name-box">
              <div class="name">{{ experimentInfo.experiment.name }}</div>
              <div class="series">{{ experimentInfo.experiment.seriesName }}</div>
            </div>
            <div>

            </div>
          </div>
          <div class="description-box">
            <div class="title">实验简介：</div>
            <div class="content">
              <p v-for="p in experimentInfo.experiment.experimentDescription.split('\n')">{{ p }}</p>
            </div>
          </div>
          <div class="view-box flex flex-between" v-if="experimentInfo.experiment.name!=='习近平新时代中国特色社会主义思想'">
            <div class="video-box" v-if="experimentInfo.experiment.product_video">
              <video id="v1" loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                     webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                     class="video1 video"
                     draggable="true"></video>
              <img src="@/assets/product/dialog/play.png" alt="" class="play-btn"
                   @click="e=>ExperimentMethods().playVideo(e)">
            </div>
            <el-image v-if="!experimentInfo.experiment.product_video&&experimentInfo.experiment.sample!==true"
                      class="video-box-no" fit="cover"
                      :src="experimentInfo.experiment.product_info_img"
                      :preview-src-list="experimentDialogPicList"></el-image>
            <div class="img-box flex flex-around flex-wrap" v-if="experimentInfo.experiment.sample!==true">
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_1"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_2"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_3"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_4"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--友情链接-->
    <a href="#" name="link"></a>
    <div class="link-container content-container">
      <el-divider content-position="center" class="content-title">友情链接</el-divider>
      <div class="list flex flex-around flex-wrap">
        <div v-for="item in linkConfig.list" v-if="item.img" @click="clickLink(item)">
          <el-image :src="item.img" alt="" class="li" fit="contain"></el-image>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!--底部-->
    <div class="page-footer">
      <div class="info-container content-container">
        <div class="infos">
          <img :src="webConfig.logoUrl" alt="" class="logo">
          <p>{{ webConfig.webName }}</p>
          <p>主办：{{ webConfig.colleageName }}</p>
          <span>技术支持：成都智云鸿道信息技术有限公司<i class="blank"></i>薪火印记（北京）文化发展有限公司</span>
          <span style="cursor: pointer" @click="window.open('https://beian.miit.gov.cn/')">
            &nbsp;&nbsp;&nbsp;&nbsp;{{
              webConfig.beiAnText
            }}</span>
        </div>
      </div>
    </div>

    <template>
      <el-backtop :bottom="50"></el-backtop>
    </template>
  </div>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog";
import {H5_URL} from "@/model/ConfigModel";
import {isMobile} from "@/utils/common";
import {OfficialWebExperimentModel} from "@/model/erp/OfficialWebExperimentModel";

export default {
  name: "web_cqcsglzyxy",
  directives: {
    elDragDialog
  },
  data() {
    return {
      window: window,
      navIndex: "1",
      webConfig: {
        "logoUrl": "http://resouce.cdzyhd.com/4131aabb-6510-433c-8d1a-1b4d85780184.jpg",
        "indexBannerUrl": "http://116.63.187.43:7035/files/20220720/216212798869344256.jpeg",
        "schoolName": "陆军军事交通学院镇江校区",
        "systemName": "思政课虚拟仿真体验教学中心",
        "colleageName": "陆军军事交通学院镇江校区",
        "focusList": [
          "http://resouce.cdzyhd.com/4cc27a28-a598-46fa-adc3-4e3ae57550cd.png",
        ],
        "webName": "陆军军事交通学院镇江校区思政课虚拟仿真体验教学中心",
        beiAnText: "蜀ICP备19034784号-1"
      },
      introduceConfig: {
        "tabs": [{
          "name": "中心简介",
          "showNav": true,
          "text": "",
          "id": "1661264029372"
        }, {
          "name": "教学团队",
          "showNav": true,
          "text": "",
          "id": "1661264039431"
        }], "name": "中心介绍"
      },
      experiments: {
        list:[]
      },
      experimentInfo: {
        dialog: false,
        experiment: {},
        list: []
      },
      memberActive: "1",
      memberList: [
        {
          list: []
        },
        {
          list: []
        },
      ],
      member: {
        dialog: false,
        info: {}
      },
      linkConfig: {
        list: [
          {
            "id": 1661270247796,
            "img": "http://resouce.cdzyhd.com/4131aabb-6510-433c-8d1a-1b4d85780184.jpg",
            "name": "陆军军事交通学院镇江校区",
            "url": "http://ljjsjtxy.zszxedu.cn/"
          },
          {
            "id": 1662270247796,
            "img": "http://resouce.cdzyhd.com/d23cf15e-2e72-4e9f-8777-b23f55fb824c.png",
            "name": "成都智云鸿道",
            "url": "http://www.cdzyhd.com/"
          }
        ]
      },
    }
  },
  created() {
    if (isMobile()) {
      window.location.href = H5_URL + this.$route.path
    }
  },
  mounted() {
    document.title = this.webConfig.webName
    // 判断是不是在移动端
    this.getExperimentConfig()
  },
  methods: {
    // 获取产品配置
    async getExperimentConfig() {
      // 要获取的实验列表，和自定义属性
      let experimentList = [
        {id: "1680005596593", name: "习近平新时代中国特色社会主义思想概论"},
        {id: "1666665667857", name: "黄继光"},
        {id: "1666668512034", name: "苏区精神"},
        {id: "1666668526771", name: "西柏坡精神"},
        {id: "1666668539421", name: "南泥湾精神"},
        {id: "1666668541545", name: "沂蒙精神"},
        {id: "1666668552526", name: "井冈山精神"},
        {id: "1666668563254", name: "伟大长征精神"},
        {id: "1666668561049", name: "伟大抗战精神"},
        {id: "1666668571403", name: "“两弹一星”精神"},
        {id: "1666668576144", name: "大庆精神"},
        {id: "1666668588955", name: "塞罕坝精神"},
        {id: "1666668613657", name: "雷锋精神"},
        {id: "1666668617760", name: "红旗渠精神"},
        {id: "1666668640306", name: "伟大抗疫精神"},
      ]
      let list = []
      for (let i = 0; i < experimentList.length; i++) {
        let id = experimentList[i]["id"]
        let experiment = await OfficialWebExperimentModel.getOneExperimentConfig(id)
        let experimentLast=Object.assign({},experiment,experimentList[i])
        // 合并自定义项
        list.push(experimentLast)
        this.$set(this.experiments, "list", list)
      }
    },
    // 点击首页导航
    clickNavLi(v) {
      this.navIndex = v;
      switch (v) {
        case "1":
          window.location.href = "#center"
          break;
        case "2":
          window.location.href = "#experiment"
          break;
        case "3":
          window.location.href = "#member"
          break;
        case "4":
          window.location.href = "#link"
          break;
        case "5":
          window.location.href = "/login?schoolId=296063516752744448"
          break;
      }
    },
    // 点击团队成员
    clickMember(item, index) {
      this.$set(this.member, "info", item)
      this.member.dialog = true
    },
    // 点击友情链接
    clickLink(item) {
      window.open(item.url)
    },
    // 实验项目方法集
    ExperimentMethods() {
      let $this = this;
      return {
        clickViewExperiment(experiment) {
          $this.experimentInfo.dialog = true
          document.body.style.overflow = 'hidden'
          $this.experimentDialogEnterBtn = false
          $this.$set($this.experimentInfo, "experiment", experiment);
          $this.experimentDialogPicList = [
            experiment.product_info_img_1,
            experiment.product_info_img_2,
            experiment.product_info_img_3,
            experiment.product_info_img_4,
          ]
          if (!experiment.product_video && experiment.product_info_img) {
            $this.experimentDialogPicList.push(experiment.product_info_img)
          }
          setTimeout(() => {
            $(".experiment-dialog .bg").css("background-image", `url(${experiment.product_info_bg})`)
          }, 100)
        },
        // 关闭实验弹窗
        closeExperimentDialog() {
          $this.experimentInfo.dialog = false
          document.body.style.overflow = ''
        },
        // 播放视频
        playVideo(e) {
          // this.videoPlay = true
          // document.querySelector("#v1").play()
          $this.experimentInfo.experimentVideoDialog = true
        }
      }
    }
  }
}
</script>
<style>
.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu--horizontal > .el-menu-item {
  margin-right: 20px !important;
  font-size: 18px !important;
  color: #777;
  letter-spacing: 2px;
  font-weight: 500;
}

.el-menu--horizontal > .el-menu-item:hover {
  transition: all 0.2s linear;
  transform: scale(1.1);
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  font-size: 16px !important;
  letter-spacing: 2px;
}

.el-menu--horizontal .el-menu .el-menu-item {
  font-size: 14px !important;
  text-align: center;
}
</style>
<style scoped lang="less">
@import '../../style/app.less';

.web-container {
  //background-color: #f2f2f2;
}

.content-container {
  &:hover {
    .el-divider__text {
      color: #4093f9;
      font-size: 24px;
    }

  }
}

.content-title {
  margin-top: 40px;

  .el-divider__text {
    font-size: 22px;
    color: #777;
    font-weight: 450;
    text-align: center;
    margin-bottom: 20px;
  }

}

.page-header {
  padding: 50px 0px;
  z-index: 1999;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-backface-visibility: hidden;
  background-image: url("http://resouce.cdzyhd.com/68afb18d-99b6-43cc-8961-8095d1d7b466.png");

  .header-container {
    width: 1280px;
    margin: 0 auto;
  }

  .web-title {
    .logo {
      width: 340px;
      height: 70px;
      margin-right: 10px;
      cursor: pointer;
    }

    .title-name {
      font-size: 28px;
      //border-left: 2px solid #fff;
      margin-left: 16px;
      padding-left: 16px;
      color: #fff;
    }
  }
}

.nav-container {
  text-align: right;
  width: 1280px;
  margin: 0 auto;

  .li {
    font-size: 16px;
    background-color: #fff;
    color: #333;
    padding: 10px 30px;
    display: inline-block;
    cursor: pointer;
  }

  .li.active {
    background: #7f1b16;
    color: #fff;
  }
}

.focus-container {

}

.li-container {
  margin-bottom: 50px;

  .content-html {
    margin-top: 45px;

    text-indent: 2em;
    font-size: 15px;
    color: #666;
    line-height: 25px;
    letter-spacing: 1px;
  }
}

.experiment-list {
  margin-top: 55px;

  .sub-list {
    .sub-li {
      margin-bottom: 20px;
      margin-right: 40px;
      position: relative;

      &:nth-child(4n+4) {
        margin-right: 0px;
      }

      .hide {
        visibility: hidden;
      }

      &:hover {
        .bg {
          img {
            transition: all 0.5s linear;
            transform: scale(1.1);
          }
        }

        .info {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

          .avatar {
            height: 60px;
            width: 60px;
            margin-top: -30px;
            opacity: 0;
          }


          .des-main {
            opacity: 0;
          }

          .title {
            margin-top: -30px;
            margin-bottom: 0px;

          }

          .des {
            display: -webkit-box;
          }

          .buttons {

            display: block;
          }
        }

      }


      img.left-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: -10px;
        left: -10px;
        z-index: 10;
      }

      .bg {
        z-index: 1;
        width: 290px;
        height: 183px;
        overflow: hidden;
        position: relative;

        img {
          width: 290px;
          height: 183px;
          display: block;
          transition: all 0.5s linear;
          transform: scale(1);

        }
      }

      .info {
        position: relative; // 解决margin-top负数 和同级div的z-index问题
        padding: 25px;
        height: 180px;
        z-index: 2;
        width: 270px;
        margin: 0 auto;
        margin-top: -60px;
        text-align: center;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-bottom: 3px solid transparent;
        transition: all 0.2s linear;
        overflow: hidden;

        .avatar {
          display: inline-block;
          width: 60px;
          height: 60px;
          position: relative;
          margin-top: 0px;
          transition: all 0.2s linear;
          border-radius: 4px;
          box-shadow: 2px 2px 5px 1px #dedede;
        }

        .title {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin-top: 18px;
          margin-bottom: 10px;
          transition: all 0.2s linear;
        }

        .des-main {
          transition: all 0.2s linear;
          color: #666;
          font-size: 14px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .des {
          font-weight: 300;
          font-size: 14px;
          display: none;
          color: #666;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .buttons {
          display: none;
          text-align: center;
          margin-top: 12px;
        }

        .button {
          width: 100px;
          line-height: 28px;
          border: 1px solid #d8d8d8;
          border-radius: 15px;
          text-align: center;
          font-size: 14px;
          color: #666666;
          position: relative;
          cursor: pointer;
          transition: all 0.2s linear;
          text-decoration: none;
          padding: 5px 20px;

          &:hover {
          }
        }

      }
    }

    .more-li {
      .info {
        background-image: url("http://resouce.cdzyhd.com/c8db5de2-7b9d-46f2-a517-35af74946245.png");
      }
    }
  }
}

.experiment-dialog {
  border-radius: 4px;
  z-index: 2000; // 解决被遮挡问题
  .dialog-box {
    border-radius: 4px;
  }


  .bg {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    height: 330px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .close-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .info-box {
    padding: 0px 80px;
  }

  .header-box {
    position: relative;
    margin-top: -115px;

    .avatar {
      width: 130px;

      border-radius: 4px;
      box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
      margin-right: 20px;
    }

    .hide {
      visibility: hidden;
      width: 0px;
      height: 130px;
    }

    .name-box {
      .name {
        font-size: 33px;
        font-weight: bold;
        line-height: 44px;
        color: #FFFFFF;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
      }

      .series {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
        margin-bottom: 8px;
      }
    }

    .enter-btn {
      margin-left: 300px;
      width: 97px;
      height: 29px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
      //background-image: url("../assets/product/dialog/enter-btn.png");
      cursor: pointer;
    }

    .view-now-btn {
      display: inline-block;
      padding: 10px 30px;
      background-color: #ff3829;
      color: #fff;
      font-size: 14px;
      margin-left: 200px;
      border-radius: 20px;
    }

  }

  .description-box {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #333333;
    margin-top: 20px;

    .title {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      color: #333;
    }

    .content {
      margin-top: 5px;
      font-size: 14px;
      text-indent: 2em;
      font-weight: 400;
      line-height: 19px;
      color: #333333;
    }
  }

  .view-box {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 35px;

    .video-box {
      width: 337px;
      height: 223px;
      position: relative;

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 337px;
        height: 223px;
        object-fit: fill;
        z-index: 1;
      }

      .play-btn {
        position: absolute;
        // 上下左右居中
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 54px;
        height: 54px;
        cursor: pointer;
        z-index: 2;
      }
    }

    .video-box-no {
      width: 337px;
      height: 223px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
    }

    .img-box {
      width: 695px;

      .img {
        width: 334px;
        height: 106px;
        cursor: pointer;

        &:nth-child(1), &:nth-child(2) {
          margin-bottom: 11px;
        }
      }
    }
  }
}

.member-list {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 55px;
  width: 1240px;

  .el-card {
    border: none;
  }

  .avatar-list {
    .avatar-li {
      cursor: pointer;
      margin-top: 15px;
      margin-left: 34px;
      margin-right: 0px;

      .el-image {
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
        opacity: 1;
        transform: scale(1);
        cursor: pointer;


        &:hover {
          transition: all 0.3s linear;
          transform: scale(1.1);
        }
      }

      .name {
        text-align: center;
        margin-top: 15px;
        color: #888;
        font-size: 15px;
      }
    }
  }
}

.member-dialog {
  .history {
    text-align: left;
    font-size: 14px;
    color: #888;
  }

  .success {
    text-align: left;
    font-size: 14px;
    color: #888;
  }
}

// 友情链接
.link-container {
  margin-bottom: 45px;

  .list {
    margin-top: 40px;
    text-align: center;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 285px);
    grid-gap: 10px;

    .name {
      margin-top: 10px;
      color: #777;
      font-size: 15px;
    }
  }


  .li {
    width: 285px;
    height: 140px;
    margin-top: 10px;
    //margin-right: 10px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    transform: scale(1);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      transition: all 0.3s linear;
      transform: scale(1.03);
    }
  }

  .more {
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
    color: #707070;

    &:hover {
      transform: scale(1.1);
    }

    span {
      font-weight: 400;
      line-height: 21px;
      color: #707070;
      font-size: 16px;
      margin-right: 3px;
    }

    img {
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-left: 4px;
    }
  }
}

.page-footer {
  background-color: #821b17;
  padding: 20px 0px;

  .info-container {
    .logo {
      width: 70px;
      height: 70px;
      cursor: pointer;
      display: none;
    }

    .infos {
      text-align: center;
      color: #fff;
      font-size: 14px;

      span {
        margin-right: 10px;
      }
    }
  }
}

.back-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99999;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
